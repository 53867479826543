/* eslint-disable react/jsx-key */
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import { Link } from "gatsby";
const SliderSection = ({images, title="", description="", reverse=false, link="#"}) => {
  let slides = images.map((image, index) => <img src={image} alt={index}></img>)
  const [carousel, setCarousel] = useState(null);
  useEffect(() => {
    let Carousel;
    try {
      Carousel = require("3d-react-carousal").Carousel;
    } catch (e) {
      console.log("e", e);
      console.log('images', images)
    }
    setCarousel(<Carousel slides={slides} />);
  }, []);
  return (
    <div className={reverse? "App flex mx-6 my-6 flex-row-reverse flex-wrap justify-center": "App flex mx-6 my-6 flex-row flex-wrap justify-center" }>
      <header className="App-header w-full md:w-2/5 mt-12">
      <h1 className="App-title text-5xl font-bold">{title}</h1>
      <div className="w-1/4 border border-black"></div>
      <p className="text-gray-700 mt-4 w-4/5 text-lg">{description}</p>
      <Link to={link} className="inline-block p-2 text-xl duration-100 border border-black rounded-lg cursor-pointer hover:bg-black hover:text-white mt-2">
          Learn more
      </Link>
      </header>
      <div className="w-3/5 hidden md:block">
      {typeof window !== "undefined" && window.document && <>{carousel}</>}
      </div>
      {/* Carousal.Carousal because of unpkg in developement use npm import and use only {Carousal}*/}
    </div>
  );
};

export default SliderSection;

SliderSection.propTypes = {
  images: PropTypes.array.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  reverse: PropTypes.bool,
  link: PropTypes.string
};

