import React from "react";
import PropTypes from "prop-types";
function Arrow({ onClick=console.log, direction="next" }) {
    const content = direction === "next" ? ">" : "<"
    const left = direction === "next" ? "97%" : "1.2%"
    return (
      <div
        className={"text-4xl block cursor-pointer absolute z-50 transform hover:cursor-pointer hover:opacity-75 hover:scale-x-110 duration-75 ease-in"}
        style={{top: "50%", left}}
        onClick={() => {
          // eslint-disable-next-line no-debugger
          debugger
          return onClick()
        }}
      >{content}</div>
    );
  }
  Arrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    direction: PropTypes.oneOf(["next", "prev"])
  };
export default Arrow;